import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "./App.css";

const Web = () => {
	return (
		<div>
			<h5>
				GeeksforGeeks is a Computer Science portal
				for geeks.
			</h5>
		</div>
	);
};

export default Web;
