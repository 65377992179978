import React from "react";
import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import Contact from "./contact.js";
import { Button } from 'reactstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import "jquery/dist/jquery.min.js";
import 'bootstrap/dist/js/bootstrap.bundle'

const Home = () => {
	return <div>
               
<a id="dApp"></a>
<div class="px-4 py-5 text-center fw-bold text-body-emphasis">
<p class="display-6 fw-bold text-body-emphasis">dToken</p>
<img class="d-block mx-auto mb-4" src="block3.jpg" alt="" width="172" height="157"/>
<h1 class="display-5 fw-bold text-body-emphasis">Blockchain Technology</h1>
<div class="col-lg-6 py-3 mx-auto">
<p class="lead mb-4">Comprehensive dApp & Blockchain consulting from our Crypto experts, based on extensive market and tech experience.
Our team of experienced developers will ensure maximum flexibility and security for your app.</p>
<div class="d-grid gap-2 d-sm-flex justify-content-sm-center">
<a href="#contact" class="btn btn-primary btn-lg px-4 gap-3">Get in touch</a>
</div>
</div>
</div>






<div class="b-example-divider">
<div class="container ">
<h2 class="pb-2 border-bottom"></h2>  
<div class="row row-cols-1 row-cols-md-2 align-items-md-center g-5 py-5">
<div class="d-flex flex-column align-items-start gap-2">
<h3 class="display-5 fw-bold text-body-emphasis">dApp</h3>
<p class="text-muted">We provide IT services for business of all sizes across the globe.  Our certified engineers support numerous business remotely and onsite. </p>
<a href="#" class="btn btn-primary btn-lg">More Info</a>
</div>
<div class="row row-cols-1 row-cols-sm-2 g-4">
<div class="d-flex flex-column gap-2">
<div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
<svg class="bi" width="1em" height="1em">
  <use xLinkHref="#collection"></use>
</svg>
</div>
<h4 class="fw-semibold mb-0">IT Service & Delivery</h4>
<p class="text-muted">We are specialists in IT Service Delivery and resourcing.  Whether its a project or BAU support we can deliver effeciencies and improved service levels.</p>
</div>  
<div class="d-flex flex-column gap-2">
<div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
<svg class="bi" width="1em" height="1em">
  <use xLinkHref="#gear-fill"></use>
</svg>
</div>
<h4 class="fw-semibold mb-0">Microsoft 365 & Azure</h4>
<p class="text-muted">Get industry-leading support for your Microsoft 365 apps.  Get more done fast with Microsoft 365 & Azure</p>
</div>  
<div class="d-flex flex-column gap-2">
<div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
<svg class="bi" width="1em" height="1em">
  <use xLinkHref="#speedometer"></use>
</svg>
</div>
<h4 class="fw-semibold mb-0">DevOps</h4>
<p class="text-muted">Outsource your DevOps projects to our nearshore talent. We can accommodate engagements of all sizes and complexities.</p>
</div>  
<div class="d-flex flex-column gap-2">
<div class="feature-icon-small d-inline-flex align-items-center justify-content-center text-bg-primary bg-gradient fs-4 rounded-3">
<svg class="bi" width="1em" height="1em">
  <use xLinkHref="#table"></use>
</svg>
</div>
<h4 class="fw-semibold mb-0">Cybersecurity</h4>
<p class="text-muted">We provide enhanced monitoring, protection and training services to keep your data secure. We can detect a breach the moment it happens and take action to prevent damage. </p>

</div>
</div>
</div>
<h2 class="pb-2 border-bottom"></h2>
</div>
<a id="contact"></a>
<Contact />
</div>

<footer class="py-3 " fixed-bottom>
<ul class="nav justify-content-center border-bottom pb-3 mb-3">
<li class="nav-item"><a href="#web" class="nav-link px-2 text-muted">Web</a></li>
<li class="nav-item"><a href="#dApp" class="nav-link px-2 text-muted">dApp & Blockchain</a></li>
<li class="nav-item"><a href="#infra" class="nav-link px-2 text-muted">Infrastructure</a></li>
</ul>
<p class="text-center text-muted">d-token.io | d-token.eth | hello@d-token.io <li><a href="https://twitter.com/d_token_io" target='_blank' rel="noreferrer"><i class="bi bi-twitter-x"></i></a> <a href="https://www.instagram.com/d_token_io/" target='_blank' rel="noreferrer"> <i class="bi bi-instagram"></i></a> <a href="/" target='_blank' rel="noreferrer"><i class="bi bi-facebook"></i></a></li></p>
</footer>
</div>

};

export default Home