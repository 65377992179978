import React from "react";
import "./App.css";
import "./block.css";


class Block extends React.Component {
    // Constructor
    constructor(props) {
        super(props);
 
        this.state = {
            items: [],
            DataisLoaded: false,
        };
    }
 
    // ComponentDidMount is used to
    // execute the code
    componentDidMount() {
        fetch("/")
            .then((res) => res.json())
            .then((json) => {
                this.setState({
                    items: json,
                    DataisLoaded: true,
                });
            });
    }
    render() {
        const { DataisLoaded, items } = this.state;
        if (!DataisLoaded)
            return (
                <div>
                    <h1><center>  </center></h1>
                    <h1><center>  </center></h1>
                    <h1><center> Fetching Blockchain </center></h1>
                </div>
            );
 
        return (
            <div className="App">
                <p></p>
                <h1 className="dtoken">d-Token Block Explorer</h1>
                <h3>Blockchain v24.1.0</h3>
                <div className="container">
                    {items.map((item) => (
                        <div className="item">
                            <ol key={item.id}>
                                <div>
                                    <strong>
                                        {"Block: "}
                                    </strong>
                                    {item.index},
                                </div>
                                <div>
                                    Previous Hash: {item.previous_hash},
                                </div>
                                <div>
                                    Timestamp: {item.timestamp},
                                </div>
                                <div>
                                    Proof of Work: {item.proof},
                                </div>                              
                            </ol>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}
 
export default Block;