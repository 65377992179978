import React from "react";

const Infra = () => {
	return (
		<div>
			<h1>Sign Up Successful</h1>
		</div>

		
	);
};

export default Infra;
